import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import {
  featureFlagsMap,
  formatDateTime,
  useAuth,
  useHasActiveFeatureFlag,
} from '@clatter/platform';
import { DataGridPremium, Link } from '@clatter/ui';

const escapeRegExp = (value) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const PresentationsListTableView = ({
  tableName,
  searchQuery = '',
  isLoading = false,
  extraColumns = [],
  actionButtons = [],
  data = [],
}) => {
  const { activeUser } = useAuth();
  const hasActiveDetailView = useHasActiveFeatureFlag(
    featureFlagsMap.PG.slideDetailView,
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(data);
  }, [data.length, isLoading]);

  const presentationsTableCols = useMemo(() => {
    const columns = [
      {
        field: 'presentationName',
        headerName: 'Name',
        renderCell: ({ row }) => {
          if (hasActiveDetailView && row?.createdBy === activeUser.id) {
            return (
              <Link
                to={generatePath('/presentation/:presentationId', {
                  presentationId: row._id,
                })}
              >
                {row.presentationName}
              </Link>
            );
          }
          return row.presentationName;
        },
        align: 'left',
        flex: 3,
      },
      {
        field: 'customerName',
        headerName: 'Client',
        align: 'left',
        flex: 2,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Updated',
        valueFormatter: (params) => formatDateTime(params.value),
        align: 'left',
        flex: 1.3,
      },
      {
        field: 'status',
        headerName: 'Status',
        align: 'left',
        flex: 1,
      },
    ];

    if (extraColumns.length) {
      extraColumns.map((column) => columns.push(column));
    }

    if (!!actionButtons) {
      columns.push({
        field: 'actions',
        headerName: 'Actions',
        flex: 1.5,
        sortable: false,
        disableExport: true,
        renderCell: actionButtons,
      });
    }

    return columns;
  }, [
    isLoading,
    actionButtons.length,
    extraColumns.length,
    hasActiveDetailView,
  ]);

  useEffect(() => {
    requestSearch(searchQuery);
  }, [searchQuery]);

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) =>
      presentationsTableCols.some((col) => {
        if ('valueGetter' in col) {
          return searchRegex.test(col.valueGetter({ row: row }));
        }
        return searchRegex.test(row[col.field]);
      }),
    );
    setRows(filteredRows);
  };

  return (
    <DataGridPremium
      name={tableName}
      loading={isLoading}
      getRowId={(row) => row._id}
      columns={presentationsTableCols}
      rows={rows}
      defaultSortField={{ field: 'updatedAt', sort: 'desc' }}
    />
  );
};

export default PresentationsListTableView;
