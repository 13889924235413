import React, { useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import presentationReducer, {
  presentationReducerActions,
} from './PresentationReducer';
import {
  deckBuildStatuses,
  fetchUserPresentation,
  presentationApi,
  presentationBuildSteps,
  PresentationContext,
  useInterval,
  useNotices,
} from '@clatter/platform';

const initialState = {
  isLoading: false,
};

function PresentationState({ children }) {
  const reduxDispatch = useDispatch();
  const [buildRequestData, setBuildRequestData] = useState({});
  const [intervalTimer, setIntervalTimer] = useState(null);
  const [state, dispatch] = useReducer(presentationReducer, initialState);
  const { addNotice } = useNotices();

  const mimicLoadingStatuses = async (includeOverview) => {
    setPresentationBuildStep(presentationBuildSteps.init);

    if (includeOverview) {
      // Temporary solution
      // Will be deleted when we get the steps from the api
      setTimeout(() => {
        setPresentationBuildStep(presentationBuildSteps.templateProcessing);

        setTimeout(() => {
          setPresentationBuildStep(presentationBuildSteps.assemblingSlides);

          setTimeout(() => {
            setPresentationBuildStep(presentationBuildSteps.toc);

            setTimeout(() => {
              setPresentationBuildStep(presentationBuildSteps.finishing);
            }, 3000);
          }, 5000);
        }, 3000);
      }, 3000);

      return;
    }

    // Temporary solution
    // Will be deleted when we get the steps from the api
    setTimeout(() => {
      setPresentationBuildStep(presentationBuildSteps.templateProcessing);

      setTimeout(() => {
        setPresentationBuildStep(presentationBuildSteps.assemblingSlides);

        setTimeout(() => {
          setPresentationBuildStep(presentationBuildSteps.finishing);
        }, 6000);
      }, 5000);
    }, 4000);
  };

  const fetchBuildStatus = async () => {
    try {
      const { data: buildData } = await presentationApi.getBuildStatus(
        buildRequestData,
      );

      if (buildData.status === deckBuildStatuses.completed) {
        reduxDispatch(fetchUserPresentation(buildRequestData));
        await setPresentationLoading(false);
        setIntervalTimer(null);
      }

      if (buildData.status === deckBuildStatuses.failed) {
        await setPresentationLoading(false);
        setIntervalTimer(null);
        return addNotice({
          message: buildData.error?.message || 'Unable to build document.',
          type: 'error',
          title: 'Error',
        });
      }
    } catch (error) {
      await setPresentationLoading(false);
      setIntervalTimer(null);
      return addNotice({
        message:
          error.response?.data?.error?.message ||
          'Unable to build document. Invalid build id.',
        type: 'error',
        title: 'Error',
      });
    }
  };

  useInterval(() => {
    fetchBuildStatus();
  }, intervalTimer);

  const setPresentationLoading = async (isLoading) =>
    dispatch({
      type: presentationReducerActions.SET_PRESENTATION_LOADING,
      payload: {
        isLoading: isLoading,
      },
    });

  const setPresentationBuildStep = async (step) =>
    dispatch({
      type: presentationReducerActions.SET_PRESENTATION_BUILD_STEP,
      payload: {
        step: step,
      },
    });

  const buildPresentation = async ({
    userId,
    presentationId,
    downloadType,
    includeOverview,
  }) => {
    try {
      mimicLoadingStatuses(includeOverview);
      await setPresentationLoading(true);
      const { data } = await presentationApi.getPresentation({
        userId: userId,
        presentationId: presentationId,
        contentType: `application/${downloadType}`,
      });


      setBuildRequestData({
        buildId: data.buildId,
        userId: userId,
        presentationId: presentationId,
      });
      setIntervalTimer(3000);
    } catch (error) {
      addNotice({
        message:
          error.response?.data?.error?.message || 'Error building Presentation',
        type: 'error',
        title: 'Error',
      });
    }
  };

  return (
    <PresentationContext.Provider
      value={{
        ...state,
        setPresentationLoading,
        buildPresentation,
      }}
    >
      {children}
    </PresentationContext.Provider>
  );
}

export default PresentationState;
