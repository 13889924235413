import { useNotices } from '.';

const isProduction = process.env.NODE_ENV === 'production';

export const useErrorHandling = () => {
  const { addNotice } = useNotices();
  const tryCatchWithNotice = async (asyncFunction, options = {}, ...args) => {
    const {
      taskId,
      successMessage,
      errorMessage = 'Error: Sorry, something went wrong!',
      finally: finallyCallback,
    } = options;

    try {
      const result = await asyncFunction(...args);

      if (successMessage) {
        addNotice({
          message: successMessage,
          type: 'success',
          title: 'Success',
        });
      }

      if (result) {
        return result;
      }
    } catch (error) {
      if (taskId && !isProduction) {
        // we can use logging errors here
        console.debug(`Error performing task: ${taskId}`, error);
      }

      addNotice({
        message: errorMessage || error?.message || 'Error: Sorry, something went wrong!',
        type: 'error',
        title: 'Error',
      });
    } finally {
      if (finallyCallback && typeof finallyCallback === 'function') {
        finallyCallback();
      }
    }
  };

  return {
    tryCatchWithNotice,
  };
};
