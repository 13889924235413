import React, { useCallback } from 'react';
import { DataGridPremium as MaterialDataGrid } from '@mui/x-data-grid-premium';
import styled from 'styled-components';

const StyledTableContainer = styled.div`
  background-color: white;

  .MuiDataGrid-root {
    font-family: 'Maven Pro', Arial, sans-serif;
    font-size: 1rem;
  }
`;

function updateRowPosition(initialIndex, newIndex, oldRows) {
  const rowsClone = [...oldRows];
  const row = rowsClone.splice(initialIndex, 1)[0];
  rowsClone.splice(newIndex, 0, row);
  return rowsClone.map((item, index) => ({
    ...item,
    order: index,
  }));
}

// MaterialDataGrid reordering will not work
// if any sort option is enabled
const ReorderTable = ({ loading = false, columns = [], rows = [], onChange }) => {
  const handleRowOrderChange = useCallback(
    (params, test) => onChange(updateRowPosition(params.oldIndex, params.targetIndex, rows)),
    [rows],
  );

  return (
    <StyledTableContainer>
      <MaterialDataGrid
        name="starter-decks-reorder" //  "report/active-users"
        columns={columns}
        loading={loading}
        rows={rows}
        autoHeight={true}
        onRowOrderChange={handleRowOrderChange}
        disableChildrenSorting
        rowReordering
      />
    </StyledTableContainer>
  );
};

export default ReorderTable;
