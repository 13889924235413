import React from 'react';
import styled from 'styled-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

const StyledPasswordValidatorStatus = styled.div`
  .validator-item {
    display: flex;
    align-items: center;

    .validator-item-icon {
      font-size: 20px;

      &.valid {
        fill: green;
      }

      &.invalid {
        fill: red;
      }
    }

    .validator-item-text {
      line-height: 20px;
      padding: 8px;
    }
  }
`;

const checkPropIsValid = ({ errors, validator, dirtyFields }) => {
  if (dirtyFields?.password && !errors) {
    return false;
  }
  return errors === undefined || errors?.hasOwnProperty('string.empty') || errors?.hasOwnProperty(validator?.errorKey);
};

const PasswordValidatorStatus = ({ errors, dirtyFields, validators = [] }) => (
  <StyledPasswordValidatorStatus>
    {validators.map((validator) => (
      <div className="validator-item" data-testid={`validator-item_${validator?.errorKey}`} key={validator.text}>
        {checkPropIsValid({ errors, validator, dirtyFields }) ? (
          <CancelIcon className="validator-item-icon invalid" />
        ) : (
          <CheckCircleOutlineIcon className="validator-item-icon valid" />
        )}
        <div className="validator-item-text">{validator.text}</div>
      </div>
    ))}
  </StyledPasswordValidatorStatus>
);

export default PasswordValidatorStatus;
