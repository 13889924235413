export const numberFormatter = (props) =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
    ...props,
  });

export const formatNumber = (number, options = { maximumFractionDigits: 0 }) => number?.toLocaleString(
  'en-US', options
);

export const secondsToMinutesAndSeconds = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = (timeInSeconds - minutes * 60).toString().padStart(2, '0');

  return `${minutes}m ${seconds}s`;
};

export const getMaskedValue = (mask, value = '') => {
  let maskArray = mask.split('');
  let valueArray = value.split('');

  for (let i = 0; i < valueArray.length; i++) {
    if (maskArray[i] === '-' && valueArray[i] !== '-') {
      valueArray.splice(i, 0, maskArray[i]);
    }
  }

  return valueArray.join('');
};
